import "./styles.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";

import about1 from "../../assets/about1.png";
import about1Mobile from "../../assets/mobile/about1.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about2_5 from "../../assets/about2_5.png";
import about2_6 from "../../assets/about2_6.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about4Mobile from "../../assets/mobile/about4.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: about2_1,
  },
  {
    id: 2,
    image: about2_2,
  },
  {
    id: 3,
    image: about2_3,
  },
  {
    id: 4,
    image: about2_4,
  },
  {
    id: 5,
    image: about2_5,
  },
  {
    id: 6,
    image: about2_6,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <figure>
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about1Mobile
                  : about1
              }
              alt="Empresas que parceiras"
            />
          </figure>
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">Nossos produtos</h2>

          <p className="text">
            Trabalhamos com as melhores marcas de pisos vinílicos e laminados,
            rodapés, painéis ripados, colas e vários outros materiais do
            segmento.
          </p>
          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <div className="gallery">
              <figure>
                <img src={about2_1} alt="Pisos Laminados" />
              </figure>

              <figure>
                <img src={about2_2} alt="Pisos Vinílicos" />
              </figure>

              <figure>
                <img src={about2_6} alt="Piso Vinílico em Manta" />
              </figure>

              <figure>
                <img src={about2_3} alt="Rodapés de Poliestireno e MDF" />
              </figure>

              <figure>
                <img src={about2_4} alt="Painéis Ripados" />
              </figure>

              <figure>
                <img
                  src={about2_5}
                  alt="Variedade em Colas para Pisos e Rodapés"
                />
              </figure>
            </div>
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 3
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <p className="text2">
            Faça agora uma cotação gratuita pelo WhatsApp:
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">Nosso diferencial</h2>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={about3_1} alt="Praticidade" />
              </figure>

              <p className="item_title">Praticidade</p>

              <p className="item_text">Produtos com garantia e qualidade</p>
            </div>

            <div className="item">
              <figure>
                <img src={about3_2} alt="Melhor Preço" />
              </figure>

              <p className="item_title">Melhor Preço</p>

              <p className="item_text">
                Parcele em até 10x no cartão de crédito.
              </p>
            </div>

            <div className="item">
              <figure>
                <img src={about3_3} alt="Entrega Rápida" />
              </figure>

              <p className="item_title">
                Entrega Rápida em toda região Sudeste
              </p>

              <p className="item_text">
                Entrega rápida em todo Rio de Janeiro e São Paulo.
              </p>
            </div>
          </div>
        </div>
      </article>

      <article className="mobile">
        <div className="container">
          <figure>
            <img
              src={about4Mobile}
              alt="Condições Especiais para Empresas, Instaladores, Engenheiros e Arquitetos."
            />
          </figure>
        </div>
      </article>

      <article className="mobile2">
        <div className="container">
          <CtaWpp />
        </div>
      </article>
    </section>
  );
};

export default About;

import { useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = () => {
  const [text, setText] = useState("(21) 99203-5344");

  return (
    <div id="ctaWpp">
      <p className="text">Solicite um orçamento pelo WhatsApp:</p>

      <button>
        <a
          href="http://wa.me/+5521992035344?text=Olá,%20Gostaria%20de%20fazer%20uma%20cotação"
          target="_blank"
          rel="noreferrer"
        >
          <figure>
            <img src={icon} alt="Ícone do Whatsapp" />
          </figure>
          <p>{text}</p>
        </a>
      </button>
    </div>
  );
};

export default CtaWpp;

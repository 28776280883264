import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo_topo from "../../assets/logo_topo.png";
import header3 from "../../assets/header3.png";
import header3Mobile from "../../assets/mobile/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="top">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">
            Pisos Vinílico, Laminados, Rodapés e muito mais para sua loja de
            acabamentos
          </h1>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            <span>
              Aqui você encontra os melhores Pisos e Acabamentos para sua obra.
            </span>{" "}
            Consulte valores especiais para Revenda.
          </h2>

          <div className="mobile">
            <p className="mobile_text">
              Parcelamos em <span>até 10x</span> <br /> sem juros nos cartões!
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <div className="left">
            <h2 className="title">Qualidade, melhor preço e pronta entrega!</h2>

            <figure className="mobile">
              <img src={header3Mobile} alt="Cobrimos qualquer oferta" />
            </figure>

            <p className="text">
              Trabalhamos com as melhores marcas de pisos vinílicos e laminados
              do mercado. Valores especiais para você, dono de loja, engenheiro,
              arquiteto ou instalador. Faça um orçamento gratuito:{" "}
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={header3} alt="Cobrimos qualquer oferta" />
          </figure>
        </article>
      </section>
    </header>
  );
};

export default Header;

import "./styles.css";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <h2 className="title">compre no atacado com CNPJ</h2>
      </section>
    </nav>
  );
};

export default Menu;

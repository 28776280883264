import React from "react";
import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo_rodape from "../../assets/logo_rodape.png";
import footer1 from "../../assets/footer1.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <h2 className="title">
            <span>Condições Especiais</span> <br />
            para Empresas, Instaladores, Engenheiros e Arquitetos.
          </h2>

          <CtaWpp />

          <figure className="right">
            <img src={footer1} alt="Homem com capacete" />
          </figure>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <figure>
            <img src={logo_rodape} alt="Logo" />
          </figure>
        </div>
      </div>

      <div className="footer3">
        <div className="container">
          <p className="text">
            Elear Distribuidora © 2024 - Todos os direitos reservados
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
